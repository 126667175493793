import styled from 'styled-components';
import { mixins, media, styleHelpers } from '@pik/pik-ui';

export const Container = styled.div`
  width: 352px;

  @media ${media.mobile} {
    width: 100%;
    max-width: 368px;
  }

  input,
  textarea {
    font-size: 16px;
  }
`;

export const Content = styled.div`
  @media ${media.mobile} {
    padding: 0 16px;
  }
`;

export const Title = styled.h1`
  margin-bottom: 34px;
  line-height: 24px;
  color: rgb(34, 34, 34);
  font-weight: 500;
  font-size: 18px;
  text-align: center;
`;

export const Error = styled.div`
  position: absolute;
  top: -24px;
  width: 100%;
  margin-bottom: 10px;
  font-size: 14px;
  line-height: 1;
  text-align: center;
  color: ${styleHelpers.colors.stateError};
  transition: opacity 0.2s ease;

  ${({ loading }) => loading
    && `
    opacity: 0;
  `};
`;

export const FieldsContainer = styled.div`
  transition: opacity 0.2s ease;

  ${({ loading }) => loading
    && `
    opacity: 0.4;
    pointer-events: none;
  `};
`;

export const FieldWrapper = styled.div`
  position: relative;
  width: 100%;
  margin-bottom: 24px;

  > div,
  input {
    height: 54px;
  }

  > div {
    > span {
      height: 54px;
    }
  }

  @media ${media.max768} {
    margin-bottom: 16px;
  }
`;

export const Peephole = styled.div`
  position: absolute;
  top: 0px;
  right: 0;
  display: flex;
  width: 38px;
  cursor: pointer;

  > svg {
    margin: auto;
    width: 24px;
    height: 24px;
  }

  &:hover svg {
    circle,
    path {
      stroke: #fc4b01;
      transition: stroke 0.2s;
    }
  }
`;
