import React, { Component } from 'react';
import { connect } from 'react-redux';

import {
  Modal,
  TextField,
  Button,
  Icons,
  styleHelpers,
  LoadingDots,
} from '@pik/pik-ui';

import ErrorPopup from '../../common/ErrorPopup';

import {
  Container,
  Content,
  Title,
  Error,
  FieldsContainer,
  FieldWrapper,
  Peephole,
} from './styles';

import { login } from '../../../services/login/actions';

import localIcons from './icons';

const DEFAULT_STATE = {
  data: {
    email: '',
    password: '',
    visiblePassword: false,
  },
};

class LoginForm extends Component {
  state = { ...DEFAULT_STATE };

  handleChange = (name, val) => {
    this.setState({
      data: {
        ...this.state.data,
        [name]: val,
      },
    });
  };

  onValidate = (name, isValid) => {
    this.setState({
      validate: {
        ...this.state.validate,
        [name]: isValid,
      },
    });
  };

  render() {
    const {
      screenType, loading, errorMessage, errorInfo, login,
    } = this.props;

    const { data } = this.state;
    const validated = data.email && data.email.length && data.password && data.password.length;
    const textFieldSize = screenType === 'mobile' ? 'm' : 'l';

    const getStatusVisiblePassword = data.visiblePassword ? 'text' : 'password';

    const toggleVisiblePassword = () => {
      data.visiblePassword = !data.visiblePassword;
      this.handleChange();
    };

    return (
      <Container>
        <Title>Войти в PIK.Team</Title>

        <ErrorPopup
          errorMessage={errorMessage}
          errorInfo={errorInfo}
          userEmail={this.state.data.email}
        />

        <Content>
          <FieldsContainer loading={loading ? 1 : 0}>
            <FieldWrapper>
              <Error loading={loading ? 1 : 0}>{errorMessage}</Error>

              <TextField
                type="text"
                inputStyle="accent"
                size={textFieldSize}
                name="email"
                placeholder="Эл. почта в формате @pik.ru"
                value={data.email}
                onChange={this.handleChange}
                onValidate={this.onValidate}
              />
            </FieldWrapper>

            <FieldWrapper>
              <TextField
                type={getStatusVisiblePassword}
                size={textFieldSize}
                name="password"
                placeholder="Пароль"
                value={data.password}
                onChange={this.handleChange}
                onValidate={this.onValidate}
              />

              <Peephole
                onClick={toggleVisiblePassword}
              >
                {data.visiblePassword ? localIcons.peepholeClosed : localIcons.peephole}
              </Peephole>
            </FieldWrapper>
          </FieldsContainer>

          <Button
            onClick={() => {
              login(data);
            }}
            type="primary"
            fullWidth
            size="l"
            disabled={!validated}
            loading={loading}
            text="Войти"
          />
        </Content>
      </Container>
    );
  }
}

const mapStateToProps = ({ loginService, appService }) => ({
  loading: loginService.loading,
  errorMessage: loginService.errorMessage,
  errorInfo: loginService.errorInfo,
  isShowErrorPopup: loginService.isShowErrorPopup,
  screenType: appService.screenType,
});

const mapDispatchToProps = {
  login,
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginForm);
